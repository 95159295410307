import React, { useEffect, useRef, useState } from 'react';
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { doc, updateDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../firebase/firestore';
import { useUser } from '../context/userContext';

export const Address = () => {
  const { id, isAddressSaved, setWalletAddress, setIsAddressSaved } = useUser();
  const [userFriendlyAddress, setUserFriendlyAddress] = useState('');
  const [disconnect, setDisconnect] = useState(false);
  const [openInfoTwo, setOpenInfoTwo] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);

  const infoRefTwo = useRef(null);
  const infoRef = useRef(null);

  // Handle click outside for popups
  const handleClickOutside = (event) => {
    if (infoRefTwo.current && !infoRefTwo.current.contains(event.target)) {
      setOpenInfoTwo(false);
    }
    if (infoRef.current && !infoRef.current.contains(event.target)) {
      setOpenInfo(false);
    }
  };

  useEffect(() => {
    if (openInfoTwo || openInfo) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openInfoTwo, openInfo]);

  // Function to clear cache
  const clearCache = () => {
    localStorage.clear();
    sessionStorage.clear();
    if ('caches' in window) {
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
    }
  };

  // Function to check if MetaMask is available
  const isMetaMaskAvailable = () => {
    return typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask;
  };

  // Function to connect MetaMask
  const connectMetaMask = async () => {
    try {
      if (!isMetaMaskAvailable()) {
        // If MetaMask is not detected
        alert('MetaMask is not installed. Please install MetaMask to continue.');
        return;
      }

      // MetaMask detected
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];
      setUserFriendlyAddress(account);
      saveAddressToFirestore(account);
    } catch (error) {
      console.error('Failed to connect MetaMask:', error);
    }
  };

  // Function to disconnect wallet
  const handleDisconnect = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    try {
      await updateDoc(userRef, {
        address: '',
        isAddressSaved: false,
      });
      setIsAddressSaved(false);
      setWalletAddress('');
      setUserFriendlyAddress('');
      setDisconnect(!disconnect);
      setOpenInfo(false);
      console.log('Address disconnected successfully');
    } catch (error) {
      console.error('Error deleting address:', error);
    }
  };

  // Function to save address to Firestore
  const saveAddressToFirestore = async (address) => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    try {
      await updateDoc(userRef, {
        address: address,
        isAddressSaved: true,
      });
      setIsAddressSaved(true);
      setWalletAddress(address);
      console.log('Address saved successfully');
    } catch (error) {
      console.error('Error saving address:', error);
    }
  };

  // Toggle disconnect confirmation
  const disco = () => {
    setDisconnect(!disconnect);
  };

  // Automatically check if MetaMask is connected when the component mounts
  useEffect(() => {
    if (isMetaMaskAvailable() && !isAddressSaved) {
      window.ethereum.request({ method: 'eth_accounts' })
        .then(accounts => {
          if (accounts.length > 0) {
            const account = accounts[0];
            setUserFriendlyAddress(account);
            saveAddressToFirestore(account);
          }
        })
        .catch(console.error);
    }
  }, []);

  return (
    <>
      <div className="w-full rounded-[15px] flex flex-col justify-center items-end relative">
        {userFriendlyAddress !== '' ? (
          <button onClick={() => setOpenInfo(true)} className={`bg-[#a4a4a424] flex h-full w-fit rounded-full items-center py-[10px] px-3 relative space-x-1`}>
            <img src='/wallet.webp' alt="connect" className="w-[16px] -mt-[2px]" />
            <div className="text-[13px] small-text2 text-left pr-3 text-nowrap text-white flex flex-1 flex-col">
              <h4 className='font-semibold text-[#d1d1d1] line-clamp-1 break-all text-wrap'>{userFriendlyAddress}</h4>
            </div>
            <IoCheckmarkCircle size={20} className='text-[#40863d]' />
          </button>
        ) : (
          <button onClick={() => setOpenInfoTwo(true)} className={`bsg-[#319cdf74] bg-[#319cdf] flex h-full w-fit rounded-full items-center py-[10px] px-3 relative space-x-1`}>
            <img src='/wallet.webp' alt="connect" className="w-[16px] -mt-[2px]" />
            <div className="text-[13px] small-text2 text-left pr-6 text-nowrap text-white flex flex-1 flex-col">
              Connect your wallet
            </div>
            <MdOutlineKeyboardArrowRight size={20} className='text-[#fff] absolute right-2' />
          </button>
        )}
      </div>

      <div className={`${openInfoTwo === true ? "visible" : "invisible"} fixed bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}>
        <div ref={infoRefTwo} className={`${openInfoTwo === true ? "opacity-100 mt-0 ease-in duration-300" : "opacity-0 mt-[100px]"} w-full bg-modal absolute bottom-0 left-0 right-0 rounded-tr-[20px] rounded-tl-[20px] flex flex-col justify-center p-8`}>
          <div className="w-fit flex justify-center absolute right-6 top-6">
            <button onClick={() => setOpenInfoTwo(false)} className="w-fit flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]">
              <IoCloseCircle size={32} className="text-[#8f8f8f]" />
            </button>
          </div>
          <div className="w-full flex justify-center flex-col items-center space-y-3 pt-6 pb-32">
            <div className="w-full items-center justify-center flex flex-col space-y-2">
              <span className="w-[72px] flex items-center">
                <img src='/wallet.webp' alt="connect" className="w-full" />
              </span>
              <h3 className='font-semibold text-[22px] w-full text-center'>Connect your MetaMask wallet</h3>
              <p className="pb-6 text-[#bfbfbf] text-[14px] w-full text-center">
                Connect your crypto wallet to receive airdrop allocation. If you don't have one, install MetaMask.
              </p>
              <div className='w-full flex flex-col items-center justify-center space-y-4'>
                <button onClick={connectMetaMask} className="bg-[#319cdf] py-2 px-8 text-white rounded-[25px]">Connect MetaMask</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
